 body {
   display: flex;
   flex-direction: column;

   margin: 0;
 }

 .home-container {
   position: relative;
   background-image: url('./pikaso_enhance__vivid_2K_Standard_r_c_ (1) (13).jpg');
   height: 700px;
   width: 100%;
   background-repeat: no-repeat;
   background-size: cover;
   background-position:center center ;

 }



 .h1-home {
   position: flex;
   padding: 1px 10%;
   font-size: 4rem;
   color: #ffffff;
   text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000000;

 }



 .home-h2 {
   position: flex;
   padding: 1px 15%;
   font-size: 2rem;
   color: #f1c40f;
   font-size: 30px;
   text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000000;

 }

 .home-action-button-container {
   position: flex;
   padding: 2% 35%;

 }

 .home-button {
   background-color: #CBB78F;
   padding: 15px 25px;
   height: 120px;
   width: 260px;
   border-radius: 8px;
   font-weight: 900;
   font-size: 1.5rem;
   border: 4px solid #000000;
   color: rgb(0, 0, 0);
   text-align: center;
   cursor: pointer;
   transform: rotate(-3deg);
   transition: transform 0.3s ease;
 }

 .home-button:hover {
   background-color: #A39170;
   border-color: #735D2C;
 }

 .test-label {
   position: absolute;
   top: 665px;
   left: 50%;
   transform: translateX(-50%);
   background-color: rgb(7, 99, 7);
   padding-top: 15px;
   padding-left: 15px;
   padding-right: 15px;
   padding-bottom: 6px;
   z-index: 10;
   text-align: center;
   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
 }

 .home-test-font {
   text-align: center;
   color: white;
 }

 .testimonal-container {
   display: flex;
   flex-wrap: wrap;
   gap: 150px;
   /* Adjust as needed for horizontal spacing */
   padding: 50px;
   justify-content: center;
   /* Center the cards horizontally */
   background-color: #F0F0F0;

 }

 .home-card-1,
 .home-card-2,
 .home-card-3 {
   flex: 1 1 300px;
   /* Grow and shrink as needed, with a base width of 300px */
   min-width: 100px;
   /* Minimum width of 100px */
   max-width: 300px;
   /* Cards will not grow larger than 300px */
   background: #f9f9f9;
   /* Light background for better readability */
   border: 1px solid #ddd;
   /* Light border to separate cards */
   border-radius: 8px;
   /* Rounded corners */
   padding: 20px;
   /* Padding inside cards */
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   /* Subtle shadow for depth */
   box-sizing: border-box;
   /* Include padding and border in the element's total width and height */
 }

 .home-card-header1,
 .home-card-header2,
 .home-card-header3 {
   font-size: 1.25em;
   /* Larger font size for headers */
   margin-bottom: 10px;
   /* Space below the header */
 }

 .test-p1,
 .test-p2,
 .test-p3 {
   font-size: 1em;
   /* Regular font size for text */
   margin-bottom: 20px;
   /* Space below the text */
 }

 .testimonial-author {
   font-style: italic;
   /* Italicize author names */
   text-align: right;
   /* Align author names to the right */
   color: #555;
   /* Slightly darker text color for contrast */
 }

 .about-me {
  width: 90%; /* Set the width of the container */
  height: 30rem ;
  display: flex;
  justify-content: space-around; /* Distribute space between items */
  align-items: center; /* Vertically center the items */
  flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
  padding: 1rem; /* Add padding to the container */
  margin: 0 auto; /* Center the container horizontally */
}

.about-img-container {
  flex: 0 1 25%; /* Allow it to shrink and grow, with a base width of 25% */
  max-width: 25%; /* Maximum width of 25% */
  height: 300px; /* Fixed height for the container */
  overflow: hidden; /* Hide any overflow content */
}

.about-img {
  border-radius: 10px;
  border: rgb(7, 99, 7) 5px solid;
  width: 100%; /* Make the image take up the full width of the container */
  height: 100%; /* Make the image take up the full height of the container */
  object-fit: cover; /* Ensure image covers the container while maintaining aspect ratio */
}

.about-p-container {
  flex: 1; /* Allow it to grow */
  max-width: 60%; /* Restrict width to a maximum of 60% */
  padding: 1rem; /* Optional padding for better readability */
  box-sizing: border-box; /* Include padding in width calculation */
}


@media only screen and (max-width: 1040px) {
  .about-me {
    display: flex; /* Corrected property for flexbox layout */
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the start of the container */
   
  }
  .about-img-container{
    display: none;
  }
  .about-img {
    display: none; /* Hide the image on smaller screens */
  }

  .about-p-container {
    flex: 1;
    min-width: 100%; /* Ensure the container takes full width */
    height: auto; /* Allow height to adjust based on content */
    max-height: 400px; /* Limit maximum height */
    overflow: auto;
    box-sizing: border-box; /* Include padding and border in width and height calculations */
    padding-bottom: 50px;
  }

}
