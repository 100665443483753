.footer {
  background-color: #D0F0C0;
  padding: 40px 20px;
  width: 100%;
}
.footer-logo{
 
    height: 10rem;
 
    margin-left: 35px;

}
.footer-content {
  
  display: flex;
  justify-content: space-between; /* Space out items horizontally */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  max-width: 1200px;
  margin: 0 auto; /* Center the content horizontally */
}

.footer-header-container {
  flex: 1 1 300px; /* Allow the header container to take 1/3rd of the space */
  padding: 20px;
  max-width: 350px;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  flex: 2; /* Allow the columns to take 2/3rds of the space */
  padding: 20px;
}

.footer-col {
  flex: 1; /* Each column takes up an equal portion of the space */
  text-align: center;
}

.footer-col-header {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.footer-links {
  list-style: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}
.footer-link{
  padding: 10px 0;
  color: #007BFF;
  text-decoration: none;
  display: block;
  margin-bottom: 1rem;
  text-align: center;
}
.footer2-links, .footer3-links {
  padding: 10px 0;
  color: #000000;
  text-decoration: none;
  display: block;
  margin-bottom: 1rem;
  text-align: center;
}

.footer-link:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Stack the header and columns vertically on smaller screens */
    align-items: center;
  }

  .footer-columns {
    flex-direction: column; /* Stack the footer columns vertically */
    width: 100%;
  }

  .footer-col {
    margin-bottom: 20px;
  }

}
