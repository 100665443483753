.custom-nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 9%;
  /* Padding for left and right */
  height: 80px;
  /* Height of the navbar */

}

.custom-nav-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* Align logo to the left */
}

.logo {
  height: 5.5rem;
  /* Logo size */
  margin-left: 20px;
  /* Space from the left edge */
}

nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  /* Take up remaining space */
}

.custom-nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 30px;
  /* Space between nav items */
}
.custom-nav-links-home {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 30px;
  /* Space between nav items */
}

.custom-nav-link-quote {
  font-size: 18px;
  color: #000;
  text-decoration: none;
  padding: 15px 25px;
  transition: color 0.3s ease;
  background-color: #CBB78F;
  border-radius: 8px;
  border: 2px solid transparent;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -5px;
}

.custom-nav-item {
  margin-right: 20px;
  /* Space from the right edge */
}


.custom-nav-link {
  font-size: 18px;
  color: #000;
  /* Text color */
  text-decoration: none;
  padding: 10px 15px;
  transition: color 0.3s ease;
}

.custom-nav-link-quote:hover {
  background-color: #B29D7F;
  /* Darker shade on hover */
  color: #fff;
  /* Change text color on hover */
}

.custom-nav-link:hover {
  color: #00bcd4;
  /* Hover color */
}
@media (max-width: 500px) {


  .custom-nav-link-quote {
    /* Remove or reset other styles if desired */
    font-size: 18px;
    color: #000;
    /* Text color */
    text-decoration: none;
    padding: 10px 15px;
    transition: color 0.3s ease;
  }
  .logo{
    margin-left: -10px;
  }
  .custom-nav-links {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 1px;
}
nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
}
}

