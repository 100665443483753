/* General styles for the admin container */
.admin-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    margin: 60px auto 0 auto; /* Add top margin for space from the top */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    height: auto; /* Allow height to adjust based on content */
}


.admin-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.admin-container form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.admin-container input[type='number'] {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
}

.admin-container button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.admin-container button:hover {
    background-color: #0056b3;
}

.admin-container div {
    margin-top: 20px;
}

.admin-container p {
    margin: 5px 0;
    font-size: 16px;
    color: #555;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .admin-container {
        padding: 15px;
    }

    .admin-container form {
        gap: 8px;
    }

    .admin-container input[type='number'],
    .admin-container button {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .admin-container {
        padding: 10px;
    }

    .admin-container input[type='number'],
    .admin-container button {
        font-size: 12px;
        padding: 8px;
    }

    .admin-container p {
        font-size: 14px;
    }
}
