

.quote-form {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns for inputs */
  grid-gap: 20px;
  background-color: rgb(194, 169, 135);
  border-radius: 10px;
  border:rgb(3, 83, 20) 5px solid;
  width:80%;
  margin: 0 auto;
  margin-top: 50px;
  padding: 20px;
}
.HEADER{
  padding-left: 20%;
}
.quote-sub-header{
  padding-left: 20%;
}

.quote-label {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  display: block;
  margin-bottom: 8px;
}

.quote-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease-in-out;
}

.quote-input:focus {
  border-color: #6c757d;
  outline: none;
}

.green-btn {
  grid-column: span 2; /* Button spans across both columns */
  background-color: green;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  justify-self: center;
  width: 75%;
}

.green-btn:hover {
  background-color: darkgreen;
}


.container-service{
  padding-top: 80px;
  width: 80%;
  margin: 0 auto; /* This centers the div horizontally */
  display: flex;
  justify-content: space-between; /* Centers the content horizontally */
  align-items: center; /* Optional: centers content vertically if the div has a height */
  
}
.service-header-1{
  width: 250px;
  height: 250px;
}
.service-image-wrapper-1{
  width: 250px;
  height: 250px;
  background-color: brown;
  border: #000000 5px solid;
}
.service-image-wrapper-2{
  width: 250px;
  height: 250px;
  background-color: brown;
  border: #000000 5px solid;
}
.service-image-wrapper-3{
  width: 250px;
  height: 250px;
  background-color: brown;
  border: #000000 5px solid;

}
.service-wrapped-img{
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the div without distorting */
}
.container-service-2{
  padding-top: 50px;
  padding-bottom: 80px;
  width: 80%;
  margin: 0 auto; /* This centers the div horizontally */
  display: flex;
  justify-content: space-between; /* Centers the content horizontally */
  align-items: center; /* Optional: centers content vertically if the div has a height */
  
}
.service-header-2{
  width: 250px;
  height: 250px;

}
.service-image-wrapper-after-1{
  width: 250px;
  height: 250px;
  background-color: brown;
  border: #000000 5px solid;
}
.service-image-wrapper-after-2{
  width: 250px;
  height: 250px;
  background-color: brown;
  border: #000000 5px solid;
}
.service-image-wrapper-after-3{
  width: 250px;
  height: 250px;
  background-color: brown;
  border: #000000 5px solid;
}
.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;


  padding: 10px;
  border-radius: 5px;
  width: 100%;
}


/* Existing styles */

/* Media query for screens smaller than 768px (mobile devices) */
@media screen and (max-width: 768px) {
  /* Form adjustments for mobile */
  .quote-form {
    grid-template-columns: 1fr; /* Make form inputs stack vertically */
    width: 100%; /* Full width on mobile */
  }
  
  .green-btn {
    width: 100%; /* Button takes full width on mobile */
  }

  /* Service container adjustments for mobile */
  .container-service,
  .container-service-2 {
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Align items in the center */
  }

  .service-header-1,
  .service-image-wrapper-1,
  .service-image-wrapper-2,
  .service-image-wrapper-3,
  .service-header-2,
  .service-image-wrapper-after-1,
  .service-image-wrapper-after-2,
  .service-image-wrapper-after-3 {
    width: 100%; /* Full width for images */
    height: auto; /* Auto height to maintain aspect ratio */
  }
  
  .service-wrapped-img {
    width: 100%;
    height: auto; /* Auto height to ensure image scales correctly */
  }
  
  .HEADER {
    padding-left: 0; /* Remove left padding on mobile */
    text-align: center; /* Center header text on mobile */
  }
}
