.contact-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
 
  max-width: 1200px;
  margin: 0 auto;
  align-items: stretch; /* Ensures both columns stretch to the same height */

  gap: 20px; /* Space between columns */
}

.left-column,
.right-column {

  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  padding-bottom: 50px;
}
.contact-info{ 
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  height: 100%; /* Make it take up full height of its container */
  width: 100%; /* Make it take up full width of its container */
  border-radius: 8px; /* Optional: rounded corners for consistency */
  padding: 20px; /* Optional: padding inside the container */
  box-sizing: border-box; /* Ensure padding is included in width/height */
}
.contact-header{
  padding-top: 1px;
}
.contact-img-container {
  
  width: 30rem; /* Use 100% width to fit within the column */
  height: 25rem; /* Adjust height based on the width for responsiveness */
  max-width: 100%; /* Restrict maximum width */
  max-height: 100%; /* Restrict maximum height */
}
.contact-image {
  border: 5px solid #69563c;; /* Solid black border, 5 pixels thick */
  border-radius: 8px;
  width: 100%; /* Make the image take up the full width of the container */
  height: 100%; /* Make the image take up the full height of the container */
  object-fit: cover; /* Cover the container, preserving aspect ratio */
  object-position: center; /* Center the image within the container */
}
.contact-form-container {
  height: 100%; /* Use 100% height to stretch within the column */
  width: 100%; /* Use 100% width to fit within the column */
  max-width: 100%; /* Restrict maximum width */
  max-height: 100%; /* Restrict maximum height */
  box-sizing: border-box; /* Include padding in width and height calculations */
}
.contact-form-group {
  display: flex;
  flex-direction: column;
  gap: 5px; /* Space between label and input/textarea */
  padding: 1rem; /* Equal padding on all sides */
}

.contact-form-group-label {
  font-size: 16px;
  font-weight: bold;
  color: #333; /* Dark color for the label text */
}

.contact-form-group-input,
.contact-form-group-textarea {
  padding: 10px;
  border: 1px solid #ccc; /* Light border for inputs and textarea */
  border-radius: 4px; /* Rounded corners for inputs and textarea */
  font-size: 16px;
  width: 100%; /* Full width of the container */
  box-sizing: border-box; /* Include padding and border in the element’s total width and height */
}

.contact-form-group-textarea {
  resize: vertical; /* Allow vertical resizing only */
}

button#green-btn {
  margin-left: 1rem; 
  width: 95%;
  background-color: rgb(7, 99, 7);
  color: white; /* White text color */
  padding: 10px 20px; /* Padding for the button */
  border: none; /* Remove default border */
  border-radius: 4px; /* Rounded corners for the button */
  font-size: 16px;
  cursor: pointer; /* Change cursor to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

button#green-btn:hover {
  background-color: #218838; /* Darker green on hover */
}




@media (max-width: 768px) {
  .contact-container {
    grid-template-columns: 1fr; /* Stack columns on smaller screens */
    padding: 20px; /* Adjust padding for smaller screens */
  }
  

  .contact-img-container {
    width: 15rem; /* Use 100% width to fit within the column */
    height: 10rem; /* Adjust height based on the width for responsiveness */
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    max-width: 100%; /* Restrict maximum width */
    max-height: 100%; /* Restrict maximum height */
  }
}
