/* Pay.css */

/* Container styling */
/* Pay.css */

/* Container styling */
.payment-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    margin: 60px auto 0 auto; /* Add top margin for space from the top */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    height: auto; /* Allow height to adjust based on content */
}

/* Title styling */
.payment-title {
    text-align: center;
    color: #333333;
    margin-bottom: 20px;
}

/* Payment info styling */
.payment-info {
    margin-bottom: 20px;
    text-align: center;
}

.payment-detail {
    margin: 5px 0;
    font-size: 16px;
    color: #555555;
}

.payment-amount {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
}

/* Form styling */
.payment-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%; /* Ensure the form takes the full width of the container */
    max-width: 500px; /* Optional: restrict the maximum width of the form */
}

.card-element {
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    background-color: #ffffff;
}

.pay-button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pay-button:hover {
    background-color: #0056b3;
}

.pay-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Responsive styles */
@media (max-width: 768px) {
    .payment-title {
        font-size: 24px;
    }

    .payment-detail, .payment-amount {
        font-size: 14px;
    }

    .card-element {
        padding: 8px;
    }

    .pay-button {
        font-size: 14px;
        padding: 8px;
    }
}

@media (max-width: 480px) {
    .payment-title {
        font-size: 20px;
    }

    .payment-detail, .payment-amount {
        font-size: 12px;
    }

    .card-element {
        padding: 6px;
    }

    .pay-button {
        font-size: 12px;
        padding: 6px;
    }
}


/* Title styling */
.payment-title {
    text-align: center;
    color: #333333;
    margin-bottom: 20px;
}

/* Payment info styling */
.payment-info {
    margin-bottom: 20px;
}

.payment-detail {
    margin: 5px 0;
    font-size: 16px;
    color: #555555;
}

.payment-amount {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
}

/* Form styling */
.payment-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card-element {
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    background-color: #ffffff;
}

.pay-button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #ffffff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pay-button:hover {
    background-color: #0056b3;
}

.pay-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

/* Responsive styles */
@media (max-width: 768px) {
    .payment-container {
        padding: 15px;
    }

    .payment-title {
        font-size: 24px;
    }

    .payment-detail, .payment-amount {
        font-size: 14px;
    }

    .card-element {
        padding: 8px;
    }

    .pay-button {
        font-size: 14px;
        padding: 8px;
    }
}

@media (max-width: 480px) {
    .payment-container {
        padding: 10px;
    }

    .payment-title {
        font-size: 20px;
    }

    .payment-detail, .payment-amount {
        font-size: 12px;
    }

    .card-element {
        padding: 6px;
    }

    .pay-button {
        font-size: 12px;
        padding: 6px;
    }
}
